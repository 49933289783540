
import styled from "styled-components";
import lock from './assets/manta/lock.png'
import { useWindowSize } from "react-use";
import bg from './assets/metis/metis.png';

const Container=styled.div`
width:100% ;
position:relative ;
height:100vh;
padding-top:80px ;
background:#000e17;
overflow:hiddren ;
background-image:url(${bg}) ;
background-size:cover;
background-position:50% ;
background-repeat: no-repeat;

@media screen and (max-width:1000px){
  background-position:right;
}
 `
 

// const PurpleText=styled.div`
// color:#60DFFF;
// font-family: 'Galyon-Book';
// text-align:left ;
// white-space: pre-wrap;
// `

const Data=styled.div`
position:absolute ;
bottom:62px ;
margin-top:203px;
text-align:left ;
z-index:9999 ;

@media screen and (max-width:750px){
    bottom:39px ;
}
@media screen and (max-width:450px){
    bottom:100px ;
}
/* padding-top:25% ; */
 `

const Desc=styled.div`
font-size: 52px;
/* font-size: 42px; */
font-family:'SF UI Text-Bold';
line-height: 80px;
color: #fff;
white-space:pre-line ;
position: absolute;
top:40%;
transform:translateY(-50%);
right:0px;

@media screen and (max-width:1350px){
  font-size: 42px;
  line-height:60px ;
  right:74px ;
}

@media screen and (max-width:550px){
  right:0 ;
  left:0 ;
  top:35%;
  text-align:center ;
  font-size:30px ;
    line-height:45px;
}

`

const Footer=styled.div`
/* display:inline-flex ; */
width:100% ;
margin-top: 14px;
font-size: 17px;
font-family: 'SF UI Text-Regular';
font-weight: normal;
line-height: 60px;
display:flex ;
align-items:center ;
padding:23px 5vw 0 5vw;
/* white-space:pre-wrap ; */
white-space:nowrap;
word-spacing:5px;
color:#fff;

&>img{
  width:30px ;
  height:auto ;
}

&>mark::before{
  content: "&";
  font-family: 'SF UI Text-Regular';
  margin:0 10px ;
}

@media screen and (max-width:750px){
    font-size:14px ;
    line-height:13px ;

    img.lock{
      width:25px ;
      height:25px !important ;
    }
}

 `
const Inner=styled.div`
width:1280px ;
height:100vh ;
margin:0 auto ;
position:relative ;


@media screen and (max-width:1280px){
  width:100% ;
}

 `


// Naming Service 
// on Mantle For Wallets, 
// Websites, and more

export function Linea(props){
  const {onEnterNextPage}=props;
  const {width}=useWindowSize()

  /**
   * Naming Service on 
Manta For Wallets, 
Websites, and more
   */
  // Naming Service on Blast for Wallets, Websites, 
//and more
    return (
      <Container style={{height:width<768? window.innerHeight:'100vh'}} >
         <Inner>
         <Desc>
                    {width>550?
                      `Naming Service on 
Metis for Wallets, 
Websites, and more`:`Naming Service 
on Metis 
for Wallets, 
Websites, 
and more`
                    }
                    </Desc>
            </Inner>
            <Data>
              {/* <LogoCard /> */}
              <Footer onClick={(e)=>onEnterNextPage(e)}>
                {/* Omni-chain Solution Powered  */}
                Powered  by  
                <img className="lock" src={lock} alt="" />
                LayerZero
                {/* <mark style={{color:"#60DFFF",background:"transparent",whiteSpace:"pre-wrap"}}></mark>
                <img style={{height:'16px',width:'auto',marginBottom:'5px'}} src={linea} alt="" /> */}
              </Footer>
          </Data>
      </Container>
    )
}




