// eslint-disable-next-line react-hooks/exhaustive-deps
import styled from "styled-components";
import { Linea } from "./lineaHomepage";
import { Cards } from "./comoments/LineaCard/index";
import { useEffect, useRef, useCallback, useState } from "react";
import { useWindowSize } from "react-use";
import logo2 from "./assets/metis/logo3.png";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  /* overflow:auto ; */
`;
const HeaderWrap = styled.header`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 23px 5vw 0 5vw;
  height: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;

  background: ${(props) => (props.currentpage === 1 ? "transparent" : "#000")};
  color: ${(props) => (props.currentpage === 1 ? "#000" : "#fff")};
  z-index: 999;
`;

const LogoWrap = styled.div`
  /* width: 320px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  font-size: 30px;
  font-weight: 600;
  color: ${(props) => props.color};
`;

const Logo = styled.img`
  width: 32px;
  height: auto;
  margin-right: 12px;
  background-color:transparent ;
  @media screen and (max-width: 1000px) {
    width: 25px;
  }
`;
const LogoText = styled.div`
  padding-top: 1px;
  font-size: 24px;
  font-family: 'SF UI Text-Medium';
  color: #fff;

  .title {
    height: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (max-width: 750px) {
    font-size: 14px;
    word-break: keep-all;
    display: none;
  }
`;

const Button = styled.div` 
padding: 8px 32px;
border-radius: 100px;
background: linear-gradient(126deg, #944DF2 12.82%, #7374C8 46.88%, #5BC0DC 91.12%);
font-size: 18px;
font-family:'SF UI Text-Medium';
color:#fff ;
cursor: pointer;
`

const Pages = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch;
`;
let clickScrolling = false;

let currentPage = 1;
function App() {
  const { height:vheight, width } = useWindowSize();
  const height = width<768 ?window.innerHeight :vheight;
  const wrapperRef = useRef();
  const headerRef = useRef();
  //   const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [page, setPage] = useState(1);

  console.log(height);

  const debounce = useCallback((func, delay) => {
    let timer;
    return function (...args) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }, []);

  const onEnterNextPage = (event) => {
    if (!clickScrolling) {
      event.stopPropagation();
      clickScrolling = true;
      const wrapper = wrapperRef.current;
      wrapper.style.transform = `translate3d(0px, -${height}px, 0px)`;
      wrapper.style.transition = `all 800ms cubic-bezier(.34,.86,.71,.95) 0s`;
      currentPage = 2;
      setTimeout(() => {
        clickScrolling = false;
      }, 800);
    }
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (currentPage === 1) {
      wrapper.style.transform = `translate3d(0px, 0px, 0px)`;
    } else if (currentPage === 2) {
      wrapper.style.transform = `translate3d(0px, -${height - 76}px, 0px)`;
    }
  }, [height]);

  useEffect(() => {
    const scrollDown = () => {
      if (!clickScrolling) {
        //clickScrolling = true;
        const wrapper = wrapperRef.current;
        if (currentPage === 1) {
          // if (height > 660)
          //   wrapper.style.transform = `translate3d(0px, -${
          //     height - 76
          //   }px, 0px)`;
          // if (height <= 660){
          //   if(height<=640){
          //     wrapper.style.transform = `translate3d(0px, -${height - 80}px, 0px)`;
          //   }else{
          //     wrapper.style.transform = `translate3d(0px, -${height}px, 0px)`;
          //   }

          // }
          wrapper.style.transform = `translate3d(0px, -${height - 76}px, 0px)`;
       
          wrapper.style.transition = `all 800ms cubic-bezier(.34,.86,.71,.95) 0s`;
          setCurrentPage2(2);
          setTimeout(() => {
            currentPage = 2;
            clickScrolling = false;
          }, 800);
          return;
        }

        if (currentPage === 2) {
          if (width > 850) {
            wrapper.style.transform = `translate3d(0px, -${
              height - 80 + 400
            }px, 0px)`;
          } else if (width <= 850 && width > 750) {
            wrapper.style.transform = `translate3d(0px, -${
              height - 80 + 420
            }px, 0px)`;
          } else {
            wrapper.style.transform = `translate3d(0px, -${
              height + 300
            }px, 0px)`;
          }
          wrapper.style.transition = `all 600ms cubic-bezier(.34,.86,.71,.95) 0s`;
          setCurrentPage2(3);
          setTimeout(() => {
            currentPage = 3;
            setPage(3);
            clickScrolling = false;
          }, 600);
        }
      }
    };

    const scrollUp = () => {
      if (!clickScrolling) {
        //clickScrolling = true;
        const wrapper = wrapperRef.current;
        const partners = document.getElementById("list");
        if (currentPage === 2) {
          wrapper.style.transform = `translate3d(0px, 0px, 0px)`;
          wrapper.style.transition = `all 800ms cubic-bezier(.34,.86,.71,.95) 0s`;
          // setCurrentPage(1)
          //setCurrentPage(1);
          setCurrentPage2(1);
          setTimeout(() => {
            currentPage = 1;
            clickScrolling = false;
          }, 800);
          return;
        }
        console.log(partners.scrollTop,'partners.scrollTop')

        if (currentPage === 3 && partners.scrollTop <= 0) {
          wrapper.style.transform = `translate3d(0px, -${height - 80}px, 0px)`;
          wrapper.style.transition = `all 600ms cubic-bezier(.34,.86,.71,.95) 0s`;
          setCurrentPage2(2);
          setTimeout(() => {
            currentPage = 2;
            setPage(2);
            clickScrolling = false;
          }, 300);
          return;
        }
      }
    };

    let scrollFunc = function (e) {
      let el = e || window.event;
      el.stopPropagation();
      if (el.wheelDelta) {
        if (el.wheelDelta > 0) {
          //当鼠标滚轮向上滚动时
          debounce(scrollUp(), 300);
        }
        if (el.wheelDelta < 0) {
          //当鼠标滚轮向下滚动时
          debounce(scrollDown(), 300);
        }
      } else if (e.detail) {
        if (el.detail < 0) {
          //当鼠标滚轮向上滚动时
          debounce(scrollUp(), 300);
        }
        if (el.detail > 0) {
          //当鼠标滚轮向下滚动时
          debounce(scrollDown(), 300);
        }
      }
    };
    // 给页面绑定鼠标滚轮事件,针对火狐的非标准事件
    window.addEventListener("DOMMouseScroll", debounce(scrollFunc, 100)); // 给页面绑定鼠标滚轮事件，针对Google，mousewheel非标准事件已被弃用，请使用 wheel事件代替
    window.addEventListener("wheel", debounce(scrollFunc, 100)); // ie不支持wheel事件，若一定要兼容，可使用mousewheel
    window.addEventListener("mousewheel", debounce(scrollFunc, 100));

    let startY = 0; // 触摸起始位置的 y 坐标
    let endY = 0; // 触摸结束位置的 y 坐标

    // 监听 touchstart 事件
    document.addEventListener("touchstart", function (e) {
      startY = e.touches[0].pageY;
    });

    // 监听 touchend 事件
    document.addEventListener("touchend", function (e) {
      endY = e.changedTouches[0].pageY;
      const deltaY = endY - startY; // 计算 y 坐标的差值
      if (deltaY > 0) {
        debounce(scrollUp(), 300);
      } else if (deltaY < 0) {
        debounce(scrollDown(), 300);
      }
    });
  }, [debounce, height, width]);

  return (
    <AppContainer>
      <HeaderWrap ref={headerRef} currentpage={currentPage2}>
        <LogoWrap>
          <Logo src={logo2}></Logo>
          <LogoText>METIS NAME SERVICE</LogoText>
        </LogoWrap>
        {/* <AppButton
                    currentpage={currentPage2}
                    onClick={(e) => { 
                    e.stopPropagation();
                    window.location.href = "https://app.okbns.domains" 
                }}>
                    <a href="https://app.okbns.domains" currentpage={currentPage2}>LAUNCH  APP</a>
                </AppButton> */}
           <Button
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = "https://app.metisns.domains";
            }}
          >
            LAUNCH APP
          </Button>
      </HeaderWrap>
      <Pages ref={wrapperRef}>
        <Linea onEnterNextPage={onEnterNextPage} />
        <Cards page={page} />
      </Pages>
    </AppContainer>
  );
}

export default App;
